export const CATEGORIES = `
query categories(
    $tenant_id: Int!, 
    $skip: Int, 
    $take: Int, 
    $orderBy: CategoryOrderInput, 
    $filter: FilterCategoryInput, 
    $searchText: String
  ){
  categories(
    tenant_id:$tenant_id, 
    skip:$skip, 
    take:$take, 
    orderBy:$orderBy, 
    filter:$filter, 
    searchText:$searchText
  ){
    __typename
    ...on Category{
      id
      name
      description
    }
    ...on ResultError{
      status_code
      message
      message_translation_key    
    }
  }
}

`;
