export const CREATE_CATEGORY = `
mutation createCategory(
    $input: CreateCategoryInput!, 
    $tenant_id: Int!
  ){
  createCategory(
    input:$input, 
    tenant_id:$tenant_id
  ){
    __typename
    ...on Category{
      id
      name
      description
    }
    ...on ResultError{
      status_code
      message
      message_translation_key
    }
  }
}
`;
export const UPDATE_CATEGORY = `
mutation updateCategory(
    $input:  UpdateCategoryInput!, 
    $tenant_id: Int!
  ){
  updateCategory(
    input:$input, 
    tenant_id:$tenant_id
  ){
    __typename
    ...on Category{
      id
      name
      description
    }
    ...on ResultError{
      status_code
      message
      message_translation_key
    }
  }
}`;
export const DELETE_CATEGORY = `
mutation deleteCategory(
    $id:Int!
    $tenant_id: Int!
  ){
  deleteCategory(
    id:$id
    tenant_id:$tenant_id
  ){
    __typename
    ...on Category{
      id
      name
      description
    }
    ...on ResultError{
      status_code
      message
      message_translation_key
    }
  }
}`;
