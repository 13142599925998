export const CREATE_STOCK_MOVEMENT = `
mutation createStockMovement(
    $input:CreateStockMovementInput!,
    $tenant_id:Int!
  ){
  createStockMovement(
    input:$input,
    tenant_id:$tenant_id
  ){
    __typename
    ...on StockMovement{
      id  
    }
    ...on ResultError{
      status_code
      message
      message_translation_key
    }
  }
}
`;
