export const STOCK_REASONS = `
query stockReasons(
  $tenant_id: Int!
  $skip: Int
  $take: Int
  $orderBy: StockReasonOrderInput
){
stockReasons(    
  tenant_id:$tenant_id
  skip:$skip
  take:$take
  orderBy:$orderBy
){
  __typename
  ...on StockReason{
    id
    translation_key
    name
    type                 
  }
  ...on ResultError{
    status_code
    message
    message_translation_key      
  }
}
}

`;
