export const ORDER_STATUSES = `
query orderStatuses(
  $tenant_id:Int!,
  $skip:Int,
  $take:Int,
  $orderBy: OrderStatusOrderInput
){
orderStatuses(
  tenant_id:$tenant_id,
  skip:$skip,
  take:$take,
  orderBy:$orderBy
){
  __typename
  ...on OrderStatus{
    id
    name
    translation_key     
    position
    hex_color
    requires_payment
  }
  ...on ResultError{
    status_code
    message
    message_translation_key      
  }  
}
}
`;
