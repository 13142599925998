export const PRODUCT_LOGS = `
query productLogs(
  $tenant_id: Int!, 
  $skip: Int, 
  $take: Int, 
  $orderBy: ProductLogOrderInput, 
  $filter: FilterProductLogInput
){
productLogs(
  tenant_id:$tenant_id, 
  skip:$skip, 
  take:$take, 
  orderBy:$orderBy, 
  filter:$filter
){
  __typename
  ...on ProductLog{
    id
    log_action{
      name
      translation_key
    }
    its
    user{
      person{
        firstname
        lastname
      }
    }
    description 
  }
  ...on ResultError{
    status_code
    message
    message_translation_key
  }
}
}
`;

export const PRODUCT_LOGS_WITH_STOCK = `
query productLogsWithStock(
  $tenant_id: Int!
  $skip: Int
  $take: Int
  $filter: FilterProductLogWithStockInput
){
productLogsWithStock(
  tenant_id:$tenant_id
  skip:$skip
  take:$take
  filter:$filter  
){
  __typename
  ...on ProductLogWithStock{
    action_name
    action_translation_key
    its
    user{
      person{
        firstname
        lastname
      }
    }
    description
    updated_dimensions
    stock_reason_type
    amount
  }
  ...on ResultError{
    status_code
    message
    message_translation_key
  }
}
}
`;
