export const CREATE_PRODUCT = `
mutation createProduct(
    $input: CreateProductInput!,
    $tenant_id: Int!,
    $files: [Upload!]
  ){
  createProduct(
    input:$input,
    tenant_id:$tenant_id,
    files:$files
  ){
    __typename
    ...on Product{
    	  id
    }
    ...on ResultError{
      status_code
      message
      message_translation_key      
    }
  }
}
`;

export const UPDATE_PRODUCT = `
mutation updateProduct(
    $input:  UpdateProductInput!,
    $tenant_id: Int!,
    $files: [Upload!]
  ){
  updateProduct(
    input:$input,
    tenant_id:$tenant_id,
    files:$files
    
  ){
    __typename
    ...on Product{
    	  id
    }
    ...on ResultError{
      status_code
      message
      message_translation_key      
    }
  }
}
`;

export const DELETE_PRODUCT = `
mutation deleteProduct(
    $id: Int!
$tenant_id: Int!,
){
deleteProduct(
id:$id
tenant_id:$tenant_id,
){
__typename
...on Product{
      id
}
...on ResultError{
  status_code
  message
  message_translation_key      
}
}
}
`;
