import React from 'react';
import './globalLight.css';

class LightTheme extends React.Component {
  componentDidMount() {
    for (const element of document.styleSheets) {
      if (
        element.cssRules[0]?.cssText?.includes('unmountableLightStylesheet')
      ) {
        element.disabled = false;
      }
    }
  }
  componentWillUnmount() {
    for (const element of document.styleSheets) {
      if (
        element.cssRules[0]?.cssText?.includes('unmountableLightStylesheet')
      ) {
        element.disabled = true;
      }
    }
  }
  render() {
    return <></>;
  }
}

export default LightTheme;
