import { NavContext } from '@ionic/react';
import { Button, Checkbox, Form, Input } from 'antd';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ContextApp } from '../../contexts/ContextApp';
import GraphqlService from '../../services/graphql/GraphqlService';
import './invitation.less';
import { useCustomMessage } from '../../hooks/useCustomMessage';
import { IInvitation } from '../../interfaces/Invitation';
import {
  useCustomLazyQuery,
  useCustomMutation,
} from '../../hooks/apollo/ApolloCustomHooks';
import { Tools } from '../../shared';
import { useForm } from 'antd/es/form/Form';
import { IAppSetting } from '../../interfaces';
import { useTranslation } from 'react-i18next';
import { EnumsValues } from '../../enums/EnumsValues';
import AcceptRejectInvitation from './AcceptRejectInvitation';

const InvitationPage = () => {
  const {
    user,
    t,
    languages,
    isRequiredAcceptTemrsAndConditions,
    checkAuth,
    getTenantsAssociatedWithUser,
    selectedTenantId,
  } = useContext(ContextApp);
  const { navigate } = useContext(NavContext);
  const location = useLocation();
  const [invitationData, setInvitationData] = useState<IInvitation>();
  const [acceptedInvitation, setAcceptedInvitation] = useState<
    boolean | undefined
  >(undefined);
  const [passwordRegex, setPasswordRegex] = useState<IAppSetting>();
  const [usernameRegex, setUsernameRegex] = useState<IAppSetting>();
  const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false);

  const [invitationUID, setInvitationUID] = useState<string | null>();
  const { Query, Mutation } = GraphqlService();
  const history = useHistory();
  const [errorResponse, setErrorResponse] = useState<boolean>(false);
  const [responseMessage, setResponseMessage] = useState<string>('');
  const [loadingInvitationResponse, setLoadingInvitationResponse] =
    useState<boolean>(false);
  const [, i18n] = useTranslation();
  const { messageError, getErrorMessage, messageUpdateSuccess } =
    useCustomMessage();

  const [form] = useForm();

  const dataFetchedRef = useRef<boolean>(false);
  // TODO: Deshabilitación registro público
  // const onClickLogin = async () => {
  //   await signInWithGoogle();
  //   const unsubscribe = firebaseApp.auth().onAuthStateChanged((user: any) => {
  //     if (user) {
  //       handleResponseInvitation(acceptedInvitation, {
  //         input: {
  //           uid: user.uid,
  //           token: user.multiFactor.user.accessToken,
  //           displayName:
  //             user.displayName || user.providerData[0].displayName || undefined,
  //           email: user.email || user.providerData[0].email || undefined,
  //           photoURL:
  //             user.photoURL || user.providerData[0].photoURL || undefined,
  //           phoneNumber:
  //             user.phoneNumber || user.providerData[0].phoneNumber || undefined,
  //           providerId: user.providerData[0].providerId,
  //         },
  //       });
  //     }
  //   });
  //   unsubscribe();
  // };

  const [fetchCheckInvitation] = useCustomLazyQuery<{
    checkInvitation: IInvitation;
  }>(Query.checkInvitation, {
    variables: {
      invitation_uid: invitationUID,
    },
  });

  const [fetchPasswordRegex] = useCustomLazyQuery<{
    getAppSettingByKey: IAppSetting;
  }>(Query.getAppSettingByKey, {
    variables: {
      input: { key: EnumsValues.SettingNames.PasswordRegex },
    },
  });

  const [fetchUsernameRegex] = useCustomLazyQuery<{
    getAppSettingByKey: IAppSetting;
  }>(Query.getAppSettingByKey, {
    variables: {
      input: { key: EnumsValues.SettingNames.UsernameRegex },
    },
  });

  const getInvitationData = async () => {
    try {
      const data = await fetchCheckInvitation().then((res) => {
        const { data, error } = res;

        if (error) {
          throw error;
        }

        return data;
      });
      setInvitationData(() => data?.checkInvitation);
    } catch (error: any) {
      setErrorResponse(true);
      setResponseMessage(error.message);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const invitation_uid = params.get('invitation_id');
    setInvitationUID(invitation_uid);
  }, [location.search]);

  useEffect(() => {
    if (invitationUID) {
      getInvitationData();
    }
  }, [invitationUID]);

  const [invitationResponseMutation] = useCustomMutation<{
    invitationResponse: any;
  }>(Mutation.invitationResponse);

  const handleResponseInvitation = useCallback(
    async (accept: any, userInput?: any) => {
      try {
        setLoadingInvitationResponse(true);
        if (invitationData)
          await invitationResponseMutation({
            variables: {
              invitation_uid: invitationData.uid,
              accept,
              ...userInput,
            },
          });

        if (user && accept) {
          await getTenantsAssociatedWithUser(selectedTenantId);
          await checkAuth();
        }
        messageUpdateSuccess({
          context: 'Invitation.handleResponseInvitation.1',
        });
        history.replace({
          search: '',
        });
        setLoadingInvitationResponse(false);
        navigate('/');
      } catch (error) {
        messageError({
          context: 'Invitation.handleResponseInvitation.2',
          message: getErrorMessage(error),
        });
      }
      setLoadingInvitationResponse(false);
    },
    [invitationData, user],
  );
  const compareToFirstPassword = (_: any, value: any) => {
    if (value && value !== form.getFieldValue('password')) {
      return Promise.reject(t('validation.passwordsNotMatch'));
    } else {
      return Promise.resolve();
    }
  };

  const getPasswordRegex = async (): Promise<void> => {
    try {
      const { data, error } = await fetchPasswordRegex();

      if (error) {
        throw error;
      }

      setPasswordRegex(data?.getAppSettingByKey);
    } catch (error: any) {
      messageError({
        context: 'InvitationPage.getPasswordRegex.1',
        message: getErrorMessage(error.networkError),
      });
    }
  };

  const getUsernameRegex = async (): Promise<void> => {
    try {
      const { data, error } = await fetchUsernameRegex();

      if (error) {
        throw error;
      }

      setUsernameRegex(data?.getAppSettingByKey);
    } catch (error: any) {
      messageError({
        context: 'InvitationPage.getUsernameRegex.1',
        message: getErrorMessage(error.networkError),
      });
    }
  };

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    getPasswordRegex();
    getUsernameRegex();
  }, []);

  return (
    <>
      <div className="invitation-page">
        {invitationData ? (
          <>
            {invitationData.accepted === true ||
            invitationData.accepted === false ? (
              <>
                <div className="invitation-page-response-info">
                  <p>
                    {Tools.capitalize(
                      t('label.theInvitationWasAlreadyRespond'),
                    )}
                  </p>
                  <Button type="primary" onClick={() => navigate('/')}>
                    {Tools.capitalize(t('action.goToHome'))}
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div className="invitation-page__container">
                  {acceptedInvitation === undefined && (
                    <>
                      {user ? (
                        <>
                          {user.email === invitationData.email ? (
                            <>
                              <AcceptRejectInvitation
                                invitationData={invitationData}
                                onAccept={() => setAcceptedInvitation(true)}
                                onReject={() => setAcceptedInvitation(false)}
                              />
                            </>
                          ) : (
                            <>
                              <div className="invitation-page-response-info">
                                <p>
                                  {Tools.capitalize(
                                    t('label.thisInvitationIsNotYours'),
                                  )}
                                </p>
                                <Button
                                  type="primary"
                                  onClick={() => navigate('/')}
                                >
                                  {Tools.capitalize(t('action.goToHome'))}
                                </Button>
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <AcceptRejectInvitation
                            invitationData={invitationData}
                            onAccept={() => setAcceptedInvitation(true)}
                            onReject={() => handleResponseInvitation(false)}
                          />
                        </>
                      )}
                    </>
                  )}
                  {!user ? (
                    <>
                      {acceptedInvitation !== undefined && (
                        <>
                          {invitationData.is_existing_user ? (
                            <>
                              <div className="invitation-page-response-info">
                                <p>
                                  {Tools.capitalize(
                                    t(
                                      'label.toRespondTheInvitationYouMustLogin',
                                    ),
                                  )}
                                </p>
                                <Button
                                  type="primary"
                                  onClick={() => {
                                    const params = new URLSearchParams({
                                      invitation_uid: invitationData.uid,
                                      accept: String(acceptedInvitation),
                                      email: invitationData.email,
                                    });
                                    navigate(`/login?${params.toString()}`);
                                  }}
                                >
                                  {Tools.capitalize(t('abm.login'))}
                                </Button>
                              </div>
                            </>
                          ) : (
                            <>
                              <p>{t('abm.completeRegisterInvitation')}</p>
                              <Form
                                onFinish={(value) => {
                                  delete value.confirm;
                                  handleResponseInvitation(acceptedInvitation, {
                                    auth: {
                                      ...value,
                                      email: invitationData.email,
                                      accepted_terms_and_conditions:
                                        acceptedTerms,
                                    },
                                  });
                                }}
                                form={form}
                              >
                                <div className="items">
                                  <Form.Item
                                    name="username"
                                    rules={[
                                      {
                                        required: true,
                                        message: t(
                                          'validation.userNameRequired',
                                        ),
                                      },
                                      {
                                        validator(_, value) {
                                          setTimeout(() => {
                                            form.validateFields(['confirm']);
                                          }, 50);
                                          if (usernameRegex) {
                                            let regex = new RegExp(
                                              String(
                                                usernameRegex?.setting_value,
                                              ),
                                              'g',
                                            );
                                            if (regex.test(value)) {
                                              return Promise.resolve();
                                            }
                                            return Promise.reject(
                                              new Error(
                                                t(
                                                  'validation.usernameRequirements',
                                                ),
                                              ),
                                            );
                                          }
                                          return Promise.resolve();
                                        },
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder={Tools.capitalize(
                                        t('entity.user'),
                                      )}
                                    />
                                  </Form.Item>
                                  {usernameRegex && (
                                    <>
                                      <p>
                                        {Tools.getSettingDescriptionTranslation(
                                          usernameRegex,
                                          i18n,
                                          languages,
                                        )}
                                      </p>
                                    </>
                                  )}
                                  <Form.Item
                                    hasFeedback
                                    name="password"
                                    rules={[
                                      {
                                        required: true,
                                        message: t(
                                          'validation.passwordRequired',
                                        ),
                                      },
                                      {
                                        validator(_, value) {
                                          setTimeout(() => {
                                            form.validateFields(['confirm']);
                                          }, 50);
                                          if (passwordRegex) {
                                            let regex = new RegExp(
                                              String(
                                                passwordRegex?.setting_value,
                                              ),
                                              'g',
                                            );
                                            if (regex.test(value)) {
                                              return Promise.resolve();
                                            }
                                            return Promise.reject(
                                              new Error(
                                                t(
                                                  'validation.passwordRequirements',
                                                ),
                                              ),
                                            );
                                          }
                                          return Promise.resolve();
                                        },
                                      },
                                    ]}
                                  >
                                    <Input.Password
                                      placeholder={Tools.capitalize(
                                        t('entity.password'),
                                      )}
                                    />
                                  </Form.Item>
                                  {passwordRegex && (
                                    <>
                                      <p>
                                        {Tools.getSettingDescriptionTranslation(
                                          passwordRegex,
                                          i18n,
                                          languages,
                                        )}
                                      </p>
                                    </>
                                  )}
                                  <Form.Item
                                    hasFeedback
                                    name="confirm"
                                    rules={[
                                      {
                                        required: true,
                                        message: t(
                                          'validation.repeatPasswordRequired',
                                        ),
                                      },
                                      {
                                        validator: compareToFirstPassword,
                                      },
                                    ]}
                                  >
                                    <Input.Password
                                      placeholder={t('abm.repeatPassword')}
                                    />
                                  </Form.Item>
                                  {isRequiredAcceptTemrsAndConditions && (
                                    <>
                                      <Form.Item>
                                        <div className="register-page__container__terms">
                                          <Checkbox
                                            type="checkbox"
                                            name="acceptTerms"
                                            className="register-page__container__terms__checkbox"
                                            onChange={(ev) =>
                                              setAcceptedTerms(
                                                () => ev.target.checked,
                                              )
                                            }
                                          >
                                            <span className="termsandconditions-footer__text-checkbox">
                                              <span>
                                                {t(
                                                  'label.iHaveReadAndIAcceptThe',
                                                )}
                                              </span>{' '}
                                              <a
                                                target="__blank"
                                                href="/termsAndConditions"
                                              >
                                                <b>
                                                  {t(
                                                    'label.termsAndConditions',
                                                  )}
                                                </b>{' '}
                                              </a>
                                            </span>
                                          </Checkbox>
                                        </div>
                                      </Form.Item>
                                    </>
                                  )}

                                  <Form.Item>
                                    <Button
                                      type="primary"
                                      htmlType="submit"
                                      style={{ width: '100%' }}
                                      loading={loadingInvitationResponse}
                                    >
                                      {Tools.capitalize(t('abm.confirm'))}
                                    </Button>
                                  </Form.Item>
                                </div>
                              </Form>
                            </>
                          )}

                          {/*// TODO: Deshabilitación registro público
              // <GoogleButton
              //   onClick={onClickLogin}
              //   title={t('abm.loginGoogle')}
            // />*/}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {acceptedInvitation !== undefined && (
                        <>
                          <div className="invitation-page-response-info">
                            <p>
                              {Tools.capitalize(
                                t(
                                  'label.toRespondTheInvitationYouMustEnterYourPassword',
                                ),
                              )}
                            </p>
                            <Form
                              onFinish={(value) => {
                                delete value.confirm;
                                handleResponseInvitation(acceptedInvitation, {
                                  auth: {
                                    username: user.username,
                                    ...value,
                                    email: user.email,
                                    accepted_terms_and_conditions:
                                      user.accepted_terms_and_conditions,
                                  },
                                });
                              }}
                              form={form}
                            >
                              <div className="items">
                                <Form.Item
                                  hasFeedback
                                  name="password"
                                  rules={[
                                    {
                                      required: true,
                                      message: t('validation.passwordRequired'),
                                    },
                                  ]}
                                >
                                  <Input.Password
                                    placeholder={Tools.capitalize(
                                      t('entity.password'),
                                    )}
                                  />
                                </Form.Item>

                                <Form.Item>
                                  <Button
                                    type="primary"
                                    htmlType="submit"
                                    style={{ width: '100%' }}
                                    loading={loadingInvitationResponse}
                                  >
                                    {Tools.capitalize(t('abm.confirm'))}
                                  </Button>
                                </Form.Item>
                              </div>
                            </Form>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              </>
            )}
          </>
        ) : (
          errorResponse && (
            <div id="invitation-page-response-message">
              <p>{responseMessage}</p>
            </div>
          )
        )}
      </div>
    </>
  );
};

export default InvitationPage;
