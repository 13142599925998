export const ORDER_LOGS = `
query orderLogs(
  $tenant_id: Int!,
  $skip: Int,
  $take: Int,
  $orderBy: OrderLogOrderInput
  $filter: FilterOrderLogInput
){
  orderLogs(
    tenant_id:$tenant_id,
    skip:$skip,
    take:$take,
    orderBy:$orderBy,
    filter:$filter
  ){
    __typename
    ...on OrderLog{
      id
      log_action{
        id
        name
        translation_key
      }
      old_status_id
      old_order_status{
        id
        name
        translation_key
        hex_color
      }
      new_status_id
      new_order_status{
        id
        name
        translation_key
        hex_color
      }
      user{
        email
        person{
          firstname
          lastname
        }        
      }
      description
      its
    }
    ...on ResultError{
      status_code
      message
      message_translation_key
    }
  }
}
`;
