import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  ApolloProvider,
  from,
  fromPromise,
  DefaultContext,
  split,
  NormalizedCacheObject,
  FetchResult,
  gql,
  Operation,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { LocalStorageKeys } from '../../enums/EnumsValues';
import { ApolloContextKey } from '../../enums/ApolloContextKey';
import { BackendError, isBackendError } from '../../shared/BackendError';
import { onError } from '@apollo/client/link/error';
import { Mutation } from '../../services/graphql/mutation';
import { createUploadLink } from 'apollo-upload-client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { IonicStorageWrapper, persistCache } from 'apollo3-cache-persist';
import { Storage } from '@ionic/storage';
import { RetryLink } from '@apollo/client/link/retry';
import QueueLink from 'apollo-link-queue';
import { v4 as uuidv4 } from 'uuid';
import {
  optimisticValuesToReplaceByOperation,
  queryRequireTenantId,
  rollbackCacheHandlerByName,
} from '../../services/graphql/cache';
import { stringify, parse } from 'flatted';
import { InternalError, Tools } from '../../shared/Tools';
import { Query } from '../../services/graphql/query';
import CustomSerializingLink from '../../links/CustomSerializingLink';
import { PageLoading } from '@ant-design/pro-layout';

export interface TrackedQuery {
  contextJSON: string;
  id: string;
  name: string;
  queryJSON: string;
  variablesJSON: string;
}

const checkMaintenanceModeAndRedirect = () => {
  if (window.location.pathname !== '/maintenance') {
    window.location.href = '/maintenance';
  }
};

const backendUri =
  import.meta.env.VITE_BACKEND_WS || 'http://localhost:3003/graphql';

const wsUri =
  import.meta.env.VITE_BACKEND_WEBSOCKET || 'ws://localhost:3003/graphql';

/**
 * Link que permite cerrar el flujo de mutaciones y encolarlas, para ser lanzadas cuando se desee
 */
const queueLink = new QueueLink();

/**
 * Link custom que permite serializar las mutaciones según las keys que el programador haya indicado en cada mutación
 */
const customSerializingLink = new CustomSerializingLink();

/**
 * Link que se encarga de reintentar las mutaciones ante un error, según se le indique
 * En este caso solo las reintenta cuando se tiene un error de conexión
 * Los reintentos se dan en un intervalo que va incrementando tras cada intento.
 * A su vez esta randomizado el intervalo de forma que no se envien demasiadas consultas al backend al mismo tiempo
 */
const retryLink = new RetryLink({
  attempts: {
    // Cantidad máxima de reintentos

    max: 5, // default
    // max: Infinity,

    retryIf(error, _operation) {
      return !Tools.isGraphqlError(error);
    },
  },
});

/**
 * La propiedad "isOptimistic" solo esta presente para el usuario, y siempre debe contener un valor booleano. Por defecto es false
 */
const isOptimisticTypePolicy = {
  fields: {
    isOptimistic: {
      read(isOptimistic = false) {
        return isOptimistic;
      },
    },
  },
};

const cache = new InMemoryCache({
  addTypename: true,
  typePolicies: {
    UserRole: {
      keyFields: ['role_id', 'user_id'], // add normalization because this type does not have an "id" field
    },
    Query: {
      fields: {
        /**
         * Permite que al consultar una compania particular, en caso de no tenerla cacheada, se consulta
         * si se encuentra cacheada en la consulta de listado de companias
         */
        company: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'Company',
              id: args?.id,
            });
          },
        },
      },
    },
    Operation: isOptimisticTypePolicy,
    Company: isOptimisticTypePolicy,
  },
});

const terminatingLink = createUploadLink({
  credentials: 'include',
  uri: backendUri,
});

const wsLink = new WebSocketLink(new SubscriptionClient(wsUri));

const addTokenLink = new ApolloLink((operation, forward) => {
  const localToken = Tools.getStorage(LocalStorageKeys.Token);
  operation.setContext({
    headers: {
      Authorization: `Bearer ${localToken}`,
    },
  });
  return forward(operation);
});

const interceptResponseForResultErrorLink = new ApolloLink(
  (operation, forward) => {
    return forward(operation).map((result) => {
      if (result.data) {
        const context = operation.getContext();
        if (context[ApolloContextKey.CUSTOM_OPERATION_NAME]) {
          const customOperationResponse =
            result.data[context[ApolloContextKey.CUSTOM_OPERATION_NAME]];

          if (
            !customOperationResponse ||
            (Array.isArray(customOperationResponse) &&
              !customOperationResponse.length)
          ) {
            return result;
          }

          const dataToInferError =
            Array.isArray(customOperationResponse) &&
            customOperationResponse.length
              ? customOperationResponse[0]
              : customOperationResponse;

          const typename: string = dataToInferError?.__typename;
          if (!typename) {
            throw new BackendError(
              null,
              'Error: data incorrecta pasado a "resultQuery".',
            );
          } else if (typename === 'ResultError') {
            if (dataToInferError.status_code === 9009) {
              checkMaintenanceModeAndRedirect();
            }
            throw new BackendError(
              dataToInferError.status_code,
              dataToInferError.message,
              dataToInferError.message_translation_key,
            );
          }
        }
      }
      if (result.errors?.[0] && isBackendError(result.errors[0])) {
        throw new BackendError(
          result.errors[0].status_code,
          result.errors[0].message,
          result.errors[0].message_translation_key,
        );
      }
      return result;
    });
  },
);

const refreshToken = async (): Promise<string> => {
  const accessToken = Tools.getStorage(LocalStorageKeys.Token);
  if (!accessToken) throw new Error('Access token not found');
  const requestHeaders: HeadersInit = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`,
  };
  const refreshTokenResponse = await fetch(backendUri, {
    method: 'POST',
    body: JSON.stringify({
      operationName: Mutation.refreshToken.name,
      query: Mutation.refreshToken.gql,
    }),
    headers: requestHeaders,
    credentials: 'include',
    cache: 'no-cache',
  });

  if (!refreshTokenResponse.ok) throw new Error('Could not refresh token');

  const response = await refreshTokenResponse.json();

  const newAccessToken = response.data?.refreshToken?.accessToken;

  if (!newAccessToken) throw new Error('Refresh token not found in response');

  Tools.setStorage(LocalStorageKeys.Token, newAccessToken);

  return newAccessToken;
};

const refreshTokenLink = onError(({ operation, forward, response }) => {
  const invalidTokenError = response?.errors?.find(
    (item) => isBackendError(item) && item.status_code === 401,
  );
  if (!invalidTokenError) {
    return;
  }
  return fromPromise(
    refreshToken()
      .then((newToken) => {
        operation.setContext((oldContext: DefaultContext) => ({
          headers: {
            ...oldContext.headers,
            Authorization: `Bearer ${newToken}`,
          },
        }));
        return forward(operation);
      })
      .catch((err) => {
        console.error(err);
        const expiredSessionEvent = new Event('sessionExpired');
        window.dispatchEvent(expiredSessionEvent);
        return err;
      }),
  ).flatMap(() => {
    return forward(operation);
  });
});

const isSubscription = ({ query }: { query: any }) => {
  const definition = getMainDefinition(query);
  return (
    definition.kind === 'OperationDefinition' &&
    definition.operation === 'subscription'
  );
};

const getOptimisticResponseCacheId = (operation: Operation) => {
  const context = operation.getContext();
  const optimisticResponse = Tools.getOptimisticResponse(context);
  if (!optimisticResponse) return;
  const entity = optimisticResponse[operation.operationName];
  const id = `${entity.__typename}:${entity.id}`;
  return id;
};

const ApolloProviderWrapper = ({ children }: { children: ReactNode }) => {
  const [online, setOnline] = useState(navigator.onLine);
  const [cachePersisted, setCachePersisted] = useState(false);
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>();
  const [trackedLoaded, setTrackedLoaded] = useState(false);
  const executeRef = useRef<Promise<PromiseSettledResult<any>[] | undefined>>();
  const clientRef = useRef<ApolloClient<NormalizedCacheObject>>();

  const getTrackedQueries = (): TrackedQuery[] => {
    return Tools.getStorage(LocalStorageKeys.TrackedQueries) || [];
  };

  function trackedQueriesAdd(trackedQuery: TrackedQuery) {
    Tools.setStorage(LocalStorageKeys.TrackedQueries, [
      ...getTrackedQueries(),
      trackedQuery,
    ]);
  }

  function trackedQueriesRemove(id: string) {
    Tools.setStorage(
      LocalStorageKeys.TrackedQueries,
      getTrackedQueries().filter((currQuery) => currQuery.id !== id),
    );
  }

  const executingMutations: number[] = [];
  const errorLink = onError((error) => {
    const {
      operation,
      networkError,
      // graphQLErrors
    } = error;
    const context = operation.getContext();
    if (context.tracked) {
      executingMutations.splice(
        executingMutations.indexOf(context.trackedQueryId),
        1,
      );
      // If there is a network error where the server throws a non-graphql error
      if (
        !networkError ||
        Tools.isGraphqlError(networkError) ||
        networkError instanceof InternalError
      ) {
        // Get rollbackQueries (cache rollbacks) for this mutation
        const rollbackQueries =
          rollbackCacheHandlerByName[
            operation.operationName as keyof typeof rollbackCacheHandlerByName
          ] || {};
        // Loop through cache items rollbacks

        for (const [query, func] of Object.entries(rollbackQueries)) {
          if (query in Query) {
            const tenantId = queryRequireTenantId.includes(query)
              ? operation.variables?.tenant_id ||
                operation.variables?.input?.tenant_id
              : undefined;

            context.cache.updateQuery(
              {
                query: gql(Query[query as keyof typeof Query].gql),
                variables: tenantId ? { tenant_id: tenantId } : undefined,
              },
              (data: any) => {
                const currentOptimisticValuesToReplaceArray = Tools.getStorage(
                  LocalStorageKeys.optimisticValuesToReplace,
                );
                let newPreviousValue = context.previousValue;
                if (newPreviousValue) {
                  newPreviousValue = JSON.parse(
                    JSON.stringify(newPreviousValue),
                  );
                  currentOptimisticValuesToReplaceArray?.forEach(
                    ([optimisticValue, realValue]) => {
                      Tools.replaceValuesInObj(
                        newPreviousValue,
                        optimisticValue,
                        realValue,
                      );
                    },
                  );
                }
                return func(
                  data,
                  Tools.getOptimisticResponse(context)[operation.operationName],
                  newPreviousValue,
                );
              },
            );
          }
        }
        trackedQueriesRemove(operation.getContext().trackedQueryId);
      }
    }
  });

  const updateRealValuesLink = new ApolloLink((operation, forward) => {
    if (forward === undefined) {
      return null;
    }

    const context = operation.getContext();
    const name = operation.operationName;

    if (!(context.tracked && name in optimisticValuesToReplaceByOperation)) {
      return forward(operation);
    }

    const optimisticData = { ...Tools.getOptimisticResponse(context) };

    const currentOptimisticValuesToReplaceArray = Tools.getStorage(
      LocalStorageKeys.optimisticValuesToReplace,
    );
    currentOptimisticValuesToReplaceArray?.forEach(
      ([optimisticValue, realValue]) => {
        Tools.replaceValuesInObj(
          operation.variables,
          optimisticValue,
          realValue,
        );
      },
    );

    return forward(operation).map((data) => {
      const getOptimisticValuesToReplace =
        optimisticValuesToReplaceByOperation[
          name as keyof typeof optimisticValuesToReplaceByOperation
        ];
      const optimisticValuesToReplace =
        getOptimisticValuesToReplace?.(
          optimisticData,
          data as FetchResult<any>,
        ) ?? [];
      const currentOptimisticValuesToReplaceArray =
        Tools.getStorage(LocalStorageKeys.optimisticValuesToReplace) || [];
      Tools.setStorage(LocalStorageKeys.optimisticValuesToReplace, [
        ...currentOptimisticValuesToReplaceArray,
        ...optimisticValuesToReplace,
      ]);

      return data;
    });
  });

  // TRACKED QUERIES
  const execute = async (mutations: number[] = []) => {
    if (executeRef.current) {
      const result = await executeRef.current;
      return result;
    } else {
      executeRef.current = (async () => {
        const promises: Array<Promise<any>> = [];
        const trackedQueries = getTrackedQueries();
        trackedQueries.forEach((trackedQuery) => {
          const context = parse(trackedQuery.contextJSON);
          const query = trackedQuery.queryJSON;
          const variables = parse(trackedQuery.variablesJSON);
          if (!mutations.includes(context.trackedQueryId))
            promises.push(
              clientRef
                .current!.mutate<any>({
                  context: {
                    ...context,
                    rerun: true,
                    _optimisticResponse: context.optimisticResponse,
                    optimisticResponse: undefined,
                  },
                  mutation: gql(query),
                  update: Tools.updateCache,
                  variables,
                })
                .catch((error) => {
                  Tools.processQueryError(error);

                  error.queryContext = context;
                  throw error;
                }),
            );
        });
        if (online) {
          let result;
          result = await Promise.allSettled(promises);
          setTrackedLoaded(true);
          return result;
        }
        setTrackedLoaded(true);
      })();
      const result = await executeRef.current;
      executeRef.current = undefined;
      return result;
    }
  };

  const persistOptimisticDataLink = new ApolloLink((operation, forward) => {
    if (forward === undefined) {
      return null;
    }
    const context = operation.getContext();
    if (
      context.tracked &&
      Tools.getOptimisticResponse(context) &&
      context.rerun !== true
    ) {
      // Persist optimistic response

      // Store data in cache ROOT LAYER
      context.cache.writeQuery({
        query: operation.query,
        data: Tools.getOptimisticResponse(context),
      });
      // Run cache updates for this mutation
      Tools.updateCache(
        context.cache,
        { data: Tools.getOptimisticResponse(context) },
        { context, variables: operation.variables },
      );
    }

    return forward(operation);
  });

  const trackerLink = new ApolloLink((operation, forward) => {
    if (forward === undefined) {
      return null;
    }
    const context = operation.getContext();

    // If query can't be offline
    if (!context.tracked) {
      return forward(operation);
    }

    // Else if query can be offline
    let id = context.trackedQueryId;

    if (!id) {
      // If query wasn't stored
      id = uuidv4();

      operation.setContext((currContext: DefaultContext) => ({
        ...currContext,
        trackedQueryId: id,
      }));
      const newContext = operation.getContext();
      const name: string = operation.operationName;
      const queryJSON: string = operation.query.loc?.source.body || '';
      const variablesJSON: string = stringify(operation.variables);
      const contextJSON = stringify({ ...newContext, cache: undefined });

      // Store query
      trackedQueriesAdd({
        contextJSON,
        id,
        name,
        queryJSON,
        variablesJSON,
      });
    }

    const exists = executingMutations.includes(id);
    if (!exists) {
      executingMutations.push(id);
    }
    return forward(operation).map((data) => {
      executingMutations.splice(executingMutations.indexOf(id), 1);
      // Remove query from store
      const cache = operation.getContext().cache;
      const optimisticResponseCacheId = getOptimisticResponseCacheId(operation);
      optimisticResponseCacheId &&
        cache.evict({ id: optimisticResponseCacheId });
      cache.gc();
      trackedQueriesRemove(id);
      return data;
    });
  });

  const waitExecutedLink = setContext(async (_, context) => {
    let connectionError;

    if (!context.rerun) {
      const result = await execute([
        ...executingMutations,
        context.trackedQueryId,
      ]);
      const errorRelated = result?.find(
        (res) =>
          res.status === 'rejected' &&
          Tools.isGraphqlError(res.reason.networkError) &&
          context.serializationKey?.length &&
          context.serializationKey.some((key: number) =>
            res.reason.queryContext.serializationKey.some(
              (key2: number) => key2 === key,
            ),
          ),
      ) as PromiseRejectedResult | undefined;
      if (errorRelated) {
        throw new InternalError(
          `Falló la consulta relacionada ${
            errorRelated.reason.queryContext[
              ApolloContextKey.CUSTOM_OPERATION_NAME
            ]
          }`,
        );
      }

      const errorDueToConnection = result?.find(
        (res) =>
          res.status === 'rejected' &&
          !Tools.isGraphqlError(res.reason.networkError) &&
          !(res.reason.networkError instanceof InternalError),
      ) as PromiseRejectedResult | undefined;
      if (errorDueToConnection) {
        connectionError = new Error(errorDueToConnection.reason.message);
      }
    }
    return { ...context, connectionError };
  });

  const throwRelatedErrorLink = new ApolloLink((operation, forward) => {
    const context = operation.getContext();
    if (context.connectionError) {
      throw context.connectionError;
    }
    return forward(operation);
  });

  const queryIgnoreQueueLink = setContext(async (req, context) => {
    const isQuery = (req.query.definitions[0] as any).operation === 'query';
    return {
      ...context,
      skipQueue: isQuery,
    };
  });

  useEffect(() => {
    const initClient = async () => {
      const store = new Storage();
      await store.create();

      await persistCache({
        cache,
        storage: new IonicStorageWrapper(store),
      });
      setCachePersisted(true);

      const clientInstance = new ApolloClient({
        cache,
        link: from([
          errorLink,
          // Request links
          persistOptimisticDataLink,
          trackerLink,
          waitExecutedLink,
          throwRelatedErrorLink, // (throws if related error in waitExecutedLink)
          queryIgnoreQueueLink,
          queueLink,
          customSerializingLink,
          updateRealValuesLink,
          retryLink,
          addTokenLink,
          // Response links
          interceptResponseForResultErrorLink,
          refreshTokenLink,
          // Terminating link
          split(
            isSubscription,
            wsLink,
            terminatingLink as unknown as ApolloLink,
          ),
        ]),
        defaultOptions: {
          query: {
            fetchPolicy: 'cache-first',
            notifyOnNetworkStatusChange: true,
          },
          watchQuery: {
            fetchPolicy: 'cache-and-network',
            notifyOnNetworkStatusChange: true,
          },
          mutate: {
            fetchPolicy: 'network-only',
          },
        },
      });

      clientRef.current = clientInstance;

      execute();

      setClient(clientInstance);
    };

    initClient().catch(console.error);
  }, []);

  useEffect(() => {
    function onlineHandler() {
      setOnline(true);
    }

    function offlineHandler() {
      setOnline(false);
    }

    window.addEventListener('online', onlineHandler);
    window.addEventListener('offline', offlineHandler);

    return () => {
      window.removeEventListener('online', onlineHandler);
      window.removeEventListener('offline', offlineHandler);
    };
  }, []);

  useEffect(() => {
    if (online) {
      queueLink.open();
    } else {
      queueLink.close();
    }
  }, [online]);

  if (!client) {
    return <PageLoading />;
  }

  if (!cachePersisted) {
    return <PageLoading />; // Loading Apollo Client Persistence
  }
  if (!trackedLoaded) {
    return <PageLoading />; // Loading Tracked Queries
  }
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloProviderWrapper;
