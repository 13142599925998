import { EditOutlined } from '@ant-design/icons';
import { Input, Tooltip } from 'antd';
import { useState, useRef, useContext, useCallback } from 'react';
import ProTable, { ProColumns, ActionType } from '@ant-design/pro-table';
import { SorterResult } from 'antd/es/table/interface';
import { SaveForm } from '../../components/common/ABM';
import { ABM, Authorization, Tools } from '../../shared';
import { useCustomMessage } from '../../hooks';
import { ContextApp } from '../../contexts/ContextApp';
import { EnumsValues } from '../../enums/EnumsValues';
import useProTableForMobile from '../../hooks/useProTableForMobile';
import {
  ITenantSetting,
  ITenantSettingValue,
} from '../../interfaces/TenantSetting';
import { ParamsType } from '@ant-design/pro-provider';
import { getPaginationArgs } from '../../shared/getPaginationArgs';
import {
  useCustomLazyQuery,
  useCustomMutation,
} from '../../hooks/apollo/ApolloCustomHooks';
import { Query } from '../../services/graphql/query';
import { Mutation } from '../../services/graphql/mutation';
import { generalConfigurationOfProtableOptions } from '../../shared/ProTableOptions';

interface IUpdateTenantSettingParams {
  description?: string;
  setting_value?: string;
}

interface IUpdateTenantSettingArgs {
  tenant_id: number;
  setting_id: number;
  value: string;
}

const LIST_FILTER = ['name', 'description'];
const LIST_SORTER = ['name'];

const TenantSettingPage: React.FC<{}> = () => {
  const {
    messageUpdating,
    messageUpdateSuccess,
    messageError,
    getErrorMessage,
  } = useCustomMessage();
  const { functions, selectedTenantId, t } = useContext(ContextApp);
  const [sorter, setSorter] = useState<string>('');
  const [searchText, setSearchText] = useState<string>('');
  const [updateModalVisible, handleUpdateModalVisible] =
    useState<boolean>(false);
  const [editForm, setEditFormValues] = useState<ITenantSetting>(
    {} as ITenantSetting,
  );

  //TODO: Traducciones

  const TITLE_PRO_TABLE = '';
  const TITLE_UPDATE_FORM = t('abm.titleUpdateForm', {
    replace: { entity: t('entity.config') },
  });
  const INPUT_SEARCH_PLACEHOLDER = t('abm.inputSearchPlaceholder');

  const [fetchTenantSettingsCount] = useCustomLazyQuery<{
    tenantSettingsCount: { count: number };
  }>(Query.tenantSettingsCount);

  const [fetchTenantSettings] = useCustomLazyQuery<{
    tenantSettings: ITenantSetting[];
  }>(Query.tenantSettings);

  const [createTenantSettingValueMutation] = useCustomMutation<{
    createTenantSettingValue: ITenantSettingValue;
  }>(Mutation.createTenantSettingValue);

  const [updateTenantSettingValueMutation] = useCustomMutation<{
    updateTenantSettingValue: ITenantSettingValue;
  }>(Mutation.updateTenantSettingValue);

  const actionRef = useRef<ActionType>();
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const variables = useRef<any>({});

  const { mobileOnSizeChangeProTable, showComponent } = useProTableForMobile({
    layout: 'horizontal',
  });

  const { TextArea } = Input;

  const handleSearch = (value: string) => {
    setSearchText(value);
    if (actionRef.current?.reloadAndRest) {
      actionRef.current.reloadAndRest();
    }
  };

  const request = async (
    params: ParamsType & {
      pageSize?: number;
      current?: number;
      keyword?: string;
    },
  ) => {
    try {
      delete variables.current.filter;
      delete variables.current.orderBy;
      variables.current = {};
      const search: any = ABM.valuesResult(params);

      if (searchText) {
        variables.current.searchText = searchText;
      } else {
        delete variables.current.searchText;
      }

      LIST_FILTER.forEach((element) => {
        try {
          if (search[element]) {
            if (!variables.current.filter) {
              variables.current.filter = {};
            }
            variables.current.filter[element] = search[element];
          }
        } catch (error) {
          // este error esta contemplado porque seguro el filtro que busca no se encuentra
        }
      });

      LIST_SORTER.forEach((element) => {
        try {
          if (search.sorter[element]) {
            if (!variables.current.orderBy) {
              variables.current.orderBy = {};
            }
            variables.current.orderBy.direction =
              Tools.getTypeOrderByTableSortParam(search.sorter[element]);
            variables.current.orderBy.field = element;
          }
        } catch (error) {
          // este error esta contemplado porque seguro el filtro que busca no se encuentra
        }
      });

      const countPromise = fetchTenantSettingsCount({
        variables: { ...variables.current, tenant_id: selectedTenantId },
      });

      const { skip, take } = getPaginationArgs(
        params.pageSize || 20,
        params.current,
      );

      variables.current.skip = skip;
      variables.current.take = take;

      const dataPromise = fetchTenantSettings({
        variables: { ...variables.current, tenant_id: selectedTenantId },
      });

      const [dataCount, dataSettingTenant] = await Promise.all([
        countPromise,
        dataPromise,
      ]);

      const { data: dataTenantSettingCount, error: errorTenantSettingCount } =
        dataCount;

      if (errorTenantSettingCount) {
        throw errorTenantSettingCount;
      }

      const { data, error } = dataSettingTenant;
      if (error) {
        throw error;
      }

      setFormLoading(false);
      return {
        current: params.current,
        data: data?.tenantSettings ?? [],
        pageSize: params.pageSize,
        success: true,
        total: dataTenantSettingCount?.tenantSettingsCount.count,
      };
    } catch (error) {
      return {
        current: params.current,
        data: [],
        pageSize: params.pageSize,
        success: false,
        total: 0,
      };
    }
  };

  const updateTenantSetting = useCallback(
    async (value: IUpdateTenantSettingParams) => {
      if (!selectedTenantId || !editForm.id || !value.setting_value) return;
      setFormLoading(() => true);

      const variables: IUpdateTenantSettingArgs = {
        tenant_id: selectedTenantId,
        setting_id: editForm.id,
        value: value.setting_value,
      };

      messageUpdating({
        context: 'TenantSettingPage.updateTenantSetting.3',
        message: t('entity.config'),
      });

      try {
        if (!editForm.tenant_settings_current_value?.length) {
          await createTenantSettingValueMutation({
            variables,
          });
        } else {
          await updateTenantSettingValueMutation({
            variables,
          });
        }

        messageUpdateSuccess({
          context: 'TenantSettingPage.updateTenantSetting.4',
        });
        handleUpdateModalVisible(false);
        if (actionRef.current) {
          actionRef.current.reload();
        }
      } catch (error: any) {
        messageError({
          context: 'TenantSettingPage.updateTenantSetting.5',
          message: getErrorMessage(error.message),
        });
      } finally {
        setFormLoading(() => false);
      }
    },
    [editForm, selectedTenantId],
  );

  const columns = useCallback(
    (): ProColumns<ITenantSetting>[] => [
      {
        title: Tools.capitalize(t('entity.id')),
        dataIndex: 'id',
        hideInTable: true,
        hideInSearch: true,
        hideInForm: true,
        fixed: 'left',
        width: 1,
      },
      {
        title: Tools.capitalize(t('entity.key')),
        dataIndex: 'name',
        hideInSearch: true,
        hideInForm: true,
        hideInTable: false,
        sorter: true,
        defaultSortOrder: 'ascend',
        render: (_, record) => (
          <Tooltip placement="top" title={record?.name || ''}>
            <div>{record?.name || ''}</div>
          </Tooltip>
        ),
      },
      {
        title: Tools.capitalize(t('entity.value')),
        dataIndex: 'setting_value',
        hideInSearch: true,
        hideInTable: false,
        render: (_, record) => {
          const value = record?.tenant_settings_current_value?.length
            ? record?.tenant_settings_current_value[0].value
            : record.default_value;

          return (
            <Tooltip placement="top" title={value || ''}>
              <div>{value || ''}</div>
            </Tooltip>
          );
        },
        renderFormItem: () => (
          <Input
            placeholder={t('abm.inputPlaceholder', {
              replace: { entity: t('entity.value') },
            })}
            maxLength={200}
          />
        ),
        formItemProps: {
          rules: [
            {
              required: true,
              message: 'El valor es requerido',
            },
          ],
        },
      },
      {
        title: Tools.capitalize(t('entity.description')),
        dataIndex: 'description',
        hideInSearch: true,
        hideInForm: false,
        hideInTable: false,
        render: (_, record) => record?.description || '',
        renderFormItem: () => (
          <TextArea
            rows={4}
            placeholder={t('abm.inputPlaceholder', {
              replace: { entity: t('entity.description') },
            })}
            maxLength={200}
            disabled
          />
        ),
      },

      {
        title: t('abm.operations'),
        dataIndex: 'option',
        valueType: 'option',
        fixed: 'right',
        width: 50,
        render: (_, record) => (
          <>
            {Authorization.security(
              functions,
              EnumsValues.Functions.UpdateTenantSetting,
            ) && (
              <Tooltip
                key="edit_setting"
                title={t('abm.editTooltip', {
                  replace: { entity: t('entity.config') },
                })}
              >
                <EditOutlined
                  className="pointer"
                  onClick={() => {
                    handleUpdateModalVisible(true);
                    setEditFormValues(() => record);
                  }}
                />
              </Tooltip>
            )}
          </>
        ),
      },
    ],
    [],
  );

  let LIST_FILTER_NAMES = columns()
    // eslint-disable-next-line array-callback-return
    .filter((value) => {
      if (
        LIST_FILTER.find(
          (element) =>
            element === value.dataIndex && value.hideInTable === false,
        )
      ) {
        return value.title;
      }
    })
    .map((element) => {
      return element.title;
    });

  return (
    <>
      <ProTable<ITenantSetting>
        onSizeChange={mobileOnSizeChangeProTable}
        components={{
          table: showComponent(),
        }}
        headerTitle={TITLE_PRO_TABLE}
        actionRef={actionRef}
        rowKey="id"
        search={false}
        onChange={(_, _filter, _sorter) => {
          const sorterResult = _sorter as SorterResult<ITenantSetting>;
          if (sorterResult.field) {
            setSorter(`${sorterResult.field}_${sorterResult.order}`);
          }
        }}
        options={generalConfigurationOfProtableOptions}
        pagination={{
          showTotal: (total, [start, end]) =>
            `${start}-${end} ${t('protable.of')} ${total} ${t(
              'protable.settings',
            )}`,
        }}
        onReset={() => {
          setSearchText('');
        }}
        params={{
          sorter,
        }}
        toolBarRender={() => [
          <div className="content-search-table" key="content-search-table">
            <Tooltip
              key="searchtext"
              title={`${Tools.capitalize(
                t('action.searchBy'),
              )} ${LIST_FILTER_NAMES.join(', ')}`}
            >
              <Input.Search
                size="middle"
                placeholder={INPUT_SEARCH_PLACEHOLDER}
                enterButton
                value={searchText}
                onSearch={handleSearch}
                onChange={(event) => {
                  setSearchText(event.target.value);
                }}
                allowClear={true}
              />
            </Tooltip>
          </div>,
        ]}
        request={async (params, sorter, filter) =>
          request({ ...params, sorter, filter })
        }
        columns={columns()}
      />

      {editForm && (
        <SaveForm
          loading={formLoading}
          title={TITLE_UPDATE_FORM}
          modalVisible={updateModalVisible}
          values={{
            setting_value: editForm.tenant_settings_current_value?.length
              ? editForm.tenant_settings_current_value[0].value
              : editForm.default_value,
            description: editForm.description,
          }}
          columns={columns()}
          onOk={async (value) => updateTenantSetting(value)}
          onCancel={() => {
            handleUpdateModalVisible(false);
            setEditFormValues({} as ITenantSetting);
          }}
        />
      )}
    </>
  );
};

export default TenantSettingPage;
