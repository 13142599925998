export const CREATE_USER = `
mutation createUser($input: CreateUserInput!, $inputPerson: CreatePersonInput, $tenant_id: Int!) {
  createUser(input: $input, inputPerson: $inputPerson, tenant_id: $tenant_id) {
      __typename
      ... on User {
        email
        id
      }
      ... on ResultError {
        status_code
        message
        message_translation_key
      }
    }
  }
`;

export const UPDATE_USER = `
mutation updateUser(
  $tenant_id: Int!,
	$input: UpdateUserInput!,
  $inputProfile: CreateProfileInput,
  $inputPerson: CreatePersonInput
  ) {
    updateUser(
      tenant_id: $tenant_id,
      input: $input,
      inputProfile: $inputProfile,
      inputPerson: $inputPerson
    ) {
      __typename
      ... on User {
        id
        language_id
        person{
          id
          user_id
          firstname
          lastname
          profile_picture_id
        }
        username
        email
        theme
        profile_id
        profile{
          phone
          timezone
        }
      }
      ... on ResultError {
        status_code
        message
        message_translation_key
      }
    }
  }
`;

export const DELETE_USER = `
mutation deleteUser($id: Int!, $tenant_id: Int!) {
  deleteUser(id: $id, tenant_id: $tenant_id) {
    __typename
    ... on User {
      id
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const RESET_PASSWORD = `
  mutation resetPassword(
    $usernameOrEmail: String,
  ){
    resetPassword(usernameOrEmail: $usernameOrEmail){
      __typename
      ...on RecoveryResult{
        result
      }
      ...on ResultError{
        status_code
        message
        message_translation_key
      }
    }
  }
`;

export const SET_PASSWORD = `
  mutation setPassword(
    $token: String!,
    $password: String!,
  )
  {
    setPassword(
        token:$token, 
        password:$password
    ){
      __typename
      ...on RecoveryResult{
        result
      }
      ...on ResultError{
        status_code
        message
        message_translation_key
      }
    }
  }
`;

export const UPSERT_USER_ROLE = `
mutation upsertUserRole($input: UpsertUserRoleInput!, $tenant_id: Int!){
  upsertUserRole(input:$input, tenant_id: $tenant_id)
  {
    __typename
    ...on UserRole{
      id
      role_id
      user_id
      role {
        id
        name
      }
    }
    ...on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const UPSERT_USER_FIREBASE = `
mutation upsertUserFirebase(
  $input: UpsertUserFirebaseInput!,
){
  upsertUserFirebase(
    input:$input,
  ){
    __typename
    ...on LoginMethod{
            uid
    }
    ...on ResultError{
      status_code
      message
      message_translation_key
    }    
  }
}
`;

export const CREATE_PROFILE_PICTURE = `
mutation createProfilePicture(
  $file:  Upload!,
  $tenant_id: Int!
){
  createProfilePicture(
    file: $file,
    tenant_id: $tenant_id,
  ){
    __typename
    ...on ProfilePicture{
      id
      filename
      mimetype
      encoding
      url
    }
    ...on ResultError{
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const UPDATE_PROFILE_PICTURE = `
mutation updateProfilePicture($file: Upload!, $tenant_id: Int!){
  updateProfilePicture(
    file: $file,
    tenant_id: $tenant_id,
  ){
    __typename
    ...on ProfilePicture{
      id
      filename
      mimetype
      encoding
      file
      url
    }
    ...on ResultError{
      status_code
      message
      message_translation_key
    }
  }
}
`;
export const DELETE_PROFILE_PICTURE = `
mutation deleteProfilePicture($id: Int!, $tenant_id: Int!){
  deleteProfilePicture(
    id: $id,
    tenant_id: $tenant_id,
  ){
    __typename
    ...on ProfilePicture{
      id
    }
    ...on ResultError{
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const UPDATE_AUTH_DATA = `
mutation updateAuthData(
  $tenant_id: Int!
  $input: UpdateAuthDataInput!
  ) {
  updateAuthData(
    tenant_id: $tenant_id
    input: $input
  ) {
    __typename
    ... on User {
      id
      username
      email
      validated
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const COMPLETE_REGISTER = `
mutation completeRegister($token: String!, $password:String){
  completeRegister(token:$token, password:$password){
    __typename
    ...on CompleteRegister{
      result
    }
    ...on ResultError{
      status_code
      message
      message_translation_key
    }
  }
}`;

export const RESEND_VALIDATE_TOKEN = `
mutation resendValidateToken($expired_token:String!){
  resendValidateToken(expired_token:$expired_token){
    __typename
    ...on CompleteRegister{
			result
    }
    ...on ResultError{
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const CREATE_USER_PUBLIC = `
mutation createUserPublic(
  $input:  CreateUserInput!
  $turnstileToken: String!
) {
  createUserPublic(
    input: $input
    turnstileToken: $turnstileToken
  ) {
    __typename
    ... on User {
      id
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
} 
`;

export const UPDATE_LAST_TENANT_ID_SELECTED = `
mutation updateLastTenantIdSelected($tenant_id: Int!) {
  updateLastTenantIdSelected(tenant_id: $tenant_id) {
    __typename
    ... on User {
      id
      last_tenant_id
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const UNLINK_USER = `
mutation unlinkUser(
  $id: Int!, 
  $tenant_id: Int!
){
unlinkUser(
  id:$id, 
  tenant_id:$tenant_id
){
  __typename
  ...on User{
    id
  }
  ...on ResultError{
    status_code
    message
    message_translation_key
  }
}
}
`;
