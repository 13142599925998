import React, {
  useState,
  useEffect,
  useContext,
  PropsWithChildren,
} from 'react';
import { Button, Modal } from 'antd';
import ProTable, { ActionType, ProColumns } from '@ant-design/pro-table';
import { ABM, Tools } from '../../../../shared';
import './style.less';
import ExceptionManager from '../../ExceptionManager';

import { PageLoading } from '@ant-design/pro-layout';
import { useSubscription } from '@apollo/client/react/hooks/useSubscription';
import gql from 'graphql-tag';
import GraphqlService from '../../../../services/graphql/GraphqlService';
import { ContextApp } from '../../../../contexts/ContextApp';
import type { ProFormInstance } from '@ant-design/pro-form';

export declare type SaveFormColumns<T = {}> = ProColumns<T> & {
  type?: string;
};

/**
 * @property modalVisible: determina si el modal es visible o no
 * @property onCancel: funcion ejecutada al cerrar el modal
 * @property onOk: funcion ejecutada al realizar submit del formulario
 * @property columns: campos a llenar en el formulario. Especificar el tipo de columna ya que será el tipo de dato que se enviará en la función on ok
 * @property title: titulo del modal
 * @property className: clases extras para estilizar el formulario de alguna forma particular. El selector a utilizar debería ser SaveForm.(clase extra)
 * @property submitText: texto a mostrar en el boton submit
 * @property buttonCancel: booleano que determina si muestra o no el boton cancelar
 * @property onReset: funcion ejecutada al limpiar el formulario
 * @property values: valores por defecto del formulario
 * @property message: mensaje a mostrar como primera línea del formulario
 * @property loading: booleano que determina si el boton submit está cargando o no
 * @property saveFormFooterIcon: desactiva o cambia los iconos actuales de los botones del footer
 */
export interface SaveFormProps extends PropsWithChildren {
  modalVisible: boolean;
  onCancel: () => void;
  onOk?: (val: any) => void;
  columns: SaveFormColumns<any>[];
  title: string;
  className?: string;
  submitText?: string;
  cancelText?: string;
  buttonCancel?: boolean;
  onReset?: Function;
  values?: object;
  message?: string;
  loading?: boolean;
  subscriptionRequest?: any;
  subscriptionVariables?: any;
  onSubscriptionEvent?: (data: any) => void;
  formItemLayout?: any;
  width?: string | number;
  style?: React.CSSProperties;
  saveFormFooterIcon?: {
    reset?: JSX.Element;
    cancel?: JSX.Element;
    submit?: JSX.Element;
  };
  disabledSubmit?: boolean;
  footer?: JSX.Element[];
  renderAsChildren?: boolean;
  actionRefProTable?:
    | React.MutableRefObject<ActionType | undefined>
    | ((actionRef: ActionType) => void);
  notIgnoreFalsyValues?: boolean;
  layoutDirection?: 'vertical' | 'horizontal' | 'inline';
  formRef?: React.MutableRefObject<ProFormInstance | undefined>;
}

const SaveFormContent: React.FC<SaveFormProps> = (props) => {
  const { user, t } = useContext(ContextApp);
  const [stColumns, setStColumns] = useState<any[]>([]);
  const [submittedForm, setSubmittedForm] = useState(false);
  const { rmTypenameObj } = GraphqlService();

  // Subscripción
  // Se le pasa un gql no existente ya que existe la opción skip, que permite saltar la subscripción bajo cierta condición, pero si el cuerpo de la subscripción no existe,
  // esto falla antes de llegar a la condición de skip, por lo que se le pasa un formato de documento válido pero que no es utilizado, para que pueda seguir adelante si no se
  // le pasa ningún gql.
  const subscriptionData = useSubscription(
    props.subscriptionRequest ||
      gql`
        subscription {
          subscription
          test {
            test
          }
        }
      `,
    {
      skip: !props.subscriptionRequest,
      variables: props.subscriptionVariables || {},
    },
  );

  const initialValuesF = (set: any) => {
    let values: any = set;
    values = rmTypenameObj(values);
    Object.keys(set).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(values, key)) {
        switch (typeof set[key]) {
          case 'boolean':
            values[key] = `${set[key]}`;
            break;
          case 'number':
            values[key] = `${set[key]}`;
            break;
          default:
            values[key] = set[key];
            break;
        }
      }
    });
    return values;
  };

  const {
    modalVisible,
    onCancel,
    onOk,
    columns,
    values,
    title,
    message,
    className,
    submitText,
    buttonCancel,
    onReset,
    loading,
    saveFormFooterIcon,
    formItemLayout,
    width,
    style,
    disabledSubmit,
    footer,
    renderAsChildren,
    actionRefProTable,
    notIgnoreFalsyValues,
    layoutDirection = 'vertical',
    formRef,
  } = props;

  const searchConfig = {
    searchText: submitText || Tools.capitalize(t('action.save')),
    resetText: values
      ? Tools.capitalize(t('action.undo'))
      : Tools.capitalize(t('action.clean')),
  };

  const setColumns = () => {
    columns.forEach((element) => {
      const el: any = element;
      delete el.export;
      delete el.sorter;
    });
    setStColumns(columns);
  };

  useEffect(() => {
    setColumns();
  }, [columns]);

  const resetFormStates = () => {
    setSubmittedForm(false);
  };

  useEffect(() => {
    if (modalVisible) {
      resetFormStates();
    }
  }, [modalVisible]);

  //Uso de subscripción para mostrar una alerta al ser actualizado el componente.
  useEffect(() => {
    if (subscriptionData.data) {
      if (props.onSubscriptionEvent && submittedForm) {
        setSubmittedForm(false);
      } else if (props.onSubscriptionEvent && !submittedForm) {
        props.onSubscriptionEvent(subscriptionData.data.operationUpdated);
        Modal.info({
          content: subscriptionData.data.operationUpdated.dts
            ? t('abm.message.itemDeleted')
            : t('abm.message.dataChanged'),
          onOk: () => {
            onCancel();
          },
        });
      }
    }
  }, [subscriptionData]);

  const layout = formItemLayout;

  const proTableComponent = () => (
    <>
      {message && <p>{message}</p>}
      {stColumns.length ? (
        <ProTable
          onSubmit={async (value) => {
            if (onOk) {
              setSubmittedForm(true);
              onOk(
                ABM.parseObjByConfigColumn(
                  columns,
                  value,
                  notIgnoreFalsyValues ? notIgnoreFalsyValues : false,
                  user,
                ),
              );
            }
          }}
          rowKey="id"
          search={searchConfig}
          actionRef={actionRefProTable}
          type="form"
          formRef={formRef}
          form={{
            ...layout,
            initialValues: values ? initialValuesF(values) : {},
            layout: layoutDirection,
            requiredMark: 'optional',
            submitter: {
              render: (props: any) => (
                <div key="SaveFormFooter" className="SaveFormFooter">
                  {footer && footer.length ? (
                    <>{footer.map((element) => element)}</>
                  ) : (
                    <>
                      <Button
                        htmlType="reset"
                        className="Reset"
                        onClick={() => {
                          props.form.resetFields();
                          if (onReset) {
                            onReset();
                          }
                        }}
                        icon={
                          saveFormFooterIcon
                            ? saveFormFooterIcon?.reset || undefined
                            : undefined
                        }
                        disabled={loading}
                      >
                        {searchConfig.resetText}
                      </Button>
                      {buttonCancel ? (
                        <Button
                          className="Cancel"
                          disabled={loading}
                          onClick={() => onCancel()}
                          icon={saveFormFooterIcon?.cancel}
                        >
                          {Tools.capitalize(t('action.cancel'))}
                        </Button>
                      ) : null}
                      <Button
                        htmlType="submit"
                        className="Submit"
                        type="primary"
                        loading={loading}
                        disabled={disabledSubmit}
                        icon={
                          saveFormFooterIcon
                            ? saveFormFooterIcon?.submit || undefined
                            : undefined
                        }
                      >
                        {searchConfig.searchText}
                      </Button>
                    </>
                  )}
                </div>
              ),
            },
          }}
          columns={stColumns}
        />
      ) : (
        <PageLoading />
      )}
    </>
  );

  return renderAsChildren ? (
    proTableComponent()
  ) : (
    <Modal
      destroyOnClose
      title={title}
      open={modalVisible}
      onCancel={() => onCancel()}
      footer={null}
      className={`SaveForm${className ? ` ${className}` : ''}`}
      width={width}
      style={style}
    >
      {proTableComponent()}
    </Modal>
  );
};

const SaveForm: React.FC<SaveFormProps> = (props) => {
  const { t } = useContext(ContextApp);

  return (
    <ExceptionManager messageModal={t('error.saveform')}>
      <SaveFormContent {...props} />
    </ExceptionManager>
  );
};

export default SaveForm;
