export const PERMISSIONS = `
query permissions($tenant_id: Int!, $skip: Int, $take: Int, $orderBy: PermissionOrderInput, $filter: FilterPermissionInput, $searchText: String) {
  permissions(tenant_id: $tenant_id, skip: $skip, take: $take, orderBy: $orderBy, filter: $filter, searchText: $searchText) {
    __typename
    ... on Permissions {
      id
      name
      code
      description
      uts
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;
