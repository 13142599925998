export const CREATE_INVITATION = `
mutation createInvitation($email: String!, $role_id: Int!, $tenant_id: Int!) {
  createInvitation (
      email: $email,
      role_id: $role_id,
      tenant_id: $tenant_id
  ) {
      __typename
      ... on Invitation {
          id
          uid
      }
      ... on ResultError {
          status_code
          message
          message_translation_key
      }
  }
}
`;

export const INVITATION_RESPONSE = `
mutation invitationResponse (
    $input: UpsertUserFirebaseInput, 
    $uid: String, 
    $invitation_uid: String!, 
    $accept: Boolean!  
  	$auth:  UpsertInvitedUserInput
) {
    invitationResponse (
        input: $input,
        uid: $uid,
        invitation_uid: $invitation_uid,
        accept: $accept
      	auth:$auth
    ) {
        __typename
        ... on Invitation {
            id
            uid
            email
            tenant { 
                id
                name
            }
            role { 
                id
                name
            }
            due_date
            accepted
        }
        ... on ResultError {
            status_code
            message
            message_translation_key
        }
    }
}
`;

export const DELETE_INVITATION = `
mutation deleteInvitation ($id: Int!, $tenant_id: Int!) {
    deleteInvitation (
        id: $id,
        tenant_id: $tenant_id
    ) {
        __typename
        ... on InvitationId {
            id
        }
        ... on ResultError {
            status_code
            message
            message_translation_key
        }
    }
}
`;

export const RESEND_INVITATION = `
mutation resendInvitation ($id: Int!, $tenant_id: Int!) {
    resendInvitation (
        id: $id,
        tenant_id: $tenant_id
    ) {
        __typename
        ... on Invitation {
            id
            uid
            tenant_id
            role_id
            email
            tenant { 
                name
            }
            role { 
                name
            }
            due_date
            accepted
        }
        ... on ResultError {
            status_code
            message
            message_translation_key
        }
    }
}
`;
