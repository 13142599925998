export const SHOPS = `
query shops(
    $tenant_id:Int!,
    $skip:Int,
    $take:Int,
    $orderBy:ShopOrderInput,
    $filter:FilterShopInput,
    $searchText:String
  ){
  shops(
    tenant_id:$tenant_id,
    skip:$skip,
    take:$take,
    orderBy:$orderBy,
    filter:$filter,
    searchText:$searchText
  ){
    __typename
    ...on Shop{
      id
      tenant_id
      name
      url
      active
      connection_status
      payment_type
      token_created_at
    }
    ...on ResultError{
      status_code
      message
      message_translation_key      
    }
  }
}
`;

export const SHOP = `
query shop(
    $tenant_id:Int!
  	$id:Int!
  ){
  shop(
    tenant_id:$tenant_id,
  	id:$id
  ){
    __typename
    ...on Shop{
      id
      tenant_id
      name
      url
      active
      connection_status
      payment_type
      token_created_at
    }
    ...on ResultError{
      status_code
      message
      message_translation_key      
    }
  }
}
`;

export const SHOP_COUNT = `
query shopCount(
  $tenant_id:Int!,    
  $orderBy:ShopOrderInput,
  $filter:FilterShopInput,
  $searchText:String
){
shopCount(
  tenant_id:$tenant_id,    
  orderBy:$orderBy,
  filter:$filter,
  searchText:$searchText
){
  __typename
  ...on Count{
     count
  }
  ...on ResultError{
    status_code
    message
    message_translation_key      
  }
}
}
`;
