import { createContext, MutableRefObject } from 'react';
import { IConfigApp, IConfig, IEnvironment } from '../interfaces/environment';
import { IUser, IUserFirebase } from '../interfaces/user';
import { IRole } from '../interfaces/role';
import { Language } from '../interfaces/language';
import { IDBPDatabase } from 'idb';
import { StringMap, TOptions } from 'i18next';
import { TParameter, TReturn } from '../i18n/i18n.model';
import { ITenant } from '../interfaces/Tenant';
import { Locale } from 'antd/lib/locale';
const configApp: any = {};
const environment: any = {};
const config: any = {};
const roles: any[] = [];
const functions: string[] = [];
const features: string[] = [];
const currentLanguage: any = {};

interface IContextApp {
  configApp: IConfigApp;
  setUser: Function;
  user?: IUser;
  setLoading: Function;
  loading: boolean;
  setLoadingMessage: Function;
  loadingMessage: string;
  setShowLoading: Function;
  showLoading: boolean;
  environment: IEnvironment;
  setEnvironment: Function;
  config: IConfig;
  setConfig: Function;
  roles: IRole[];
  setRoles: Function;
  functions: string[];
  setFunctions: Function;
  features: string[];
  setFeatures: Function;
  dataLoaded: boolean;
  setDataLoaded: Function;
  checkAuth: (lastTenantId?: number) => Promise<void>;
  mainMenuCollapsed: boolean;
  setMainMenuCollapsed: Function;
  menuBreakpointCollapsed: boolean;
  setMenuBreakpointCollapsed: Function;
  sessionExpired: boolean;
  setSessionExpired: Function;
  languages: Language[];
  userDataFirebase?: IUserFirebase;
  setUserDataFirebase: Function;
  pictureProfileUrl: string;
  setPictureProfileUrl: Function;
  show2FAReminder: boolean;
  setShow2FAReminder: Function;
  indexedDatabaseRef: MutableRefObject<IDBPDatabase<unknown> | null>;
  maintenanceMode: boolean;
  setMaintenanceMode: React.Dispatch<React.SetStateAction<boolean>>;
  isRequiredAcceptTemrsAndConditions: boolean;
  setIsRequiredAcceptTemrsAndConditions: Function;
  t: <S extends TParameter>(p: S, options?: TOptions<StringMap>) => TReturn<S>;
  termsAndConditionsVersionActive: string;
  setTermsAndConditionsVersionActive: Function;
  userHasAcceptedTermsAndConditions: boolean;
  setUserHasAcceptedTermsAndConditions: Function;
  getTermsAndConditions: Function;
  fullNameMandatory: boolean | undefined;
  setFullNameMandatory: Function;
  tenantsAssociatedToUser: ITenant[];
  setTenantsAssociatedToUser: Function;
  getTenantsAssociatedWithUser: (
    lastTenantId: number | undefined,
  ) => Promise<ITenant[]>;
  selectedTenantId: number | undefined;
  setSelectedTenantId: Function;
  isAppMultitenant: boolean;
  isProfileCompleted: boolean;
  setIsProfileCompleted: Function;
  currentLanguage: Locale;
}

const context: IContextApp = {
  configApp,
  setUser: () => {},
  user: undefined,
  setLoading: () => {},
  loading: false,
  setLoadingMessage: () => {},
  loadingMessage: '',
  setShowLoading: () => {},
  showLoading: true,
  environment,
  setEnvironment: () => {},
  config,
  setConfig: () => {},
  setRoles: () => {},
  setFunctions: () => {},
  setFeatures: () => {},
  features,
  roles,
  functions,
  dataLoaded: false,
  setDataLoaded: () => {},
  checkAuth: async () => {},
  mainMenuCollapsed: false,
  setMainMenuCollapsed: () => {},
  menuBreakpointCollapsed: false,
  setMenuBreakpointCollapsed: () => {},
  sessionExpired: false,
  setSessionExpired: () => {},
  languages: [],
  setUserDataFirebase: () => {},
  userDataFirebase: undefined,
  pictureProfileUrl: '',
  setPictureProfileUrl: () => {},
  show2FAReminder: false,
  setShow2FAReminder: () => {},
  indexedDatabaseRef: { current: null },
  maintenanceMode: false,
  setMaintenanceMode: () => {
    // Intentional
  },
  isRequiredAcceptTemrsAndConditions: false,
  setIsRequiredAcceptTemrsAndConditions: () => {},
  t: () => '' as any,
  termsAndConditionsVersionActive: '',
  setTermsAndConditionsVersionActive: () => {},
  userHasAcceptedTermsAndConditions: false,
  setUserHasAcceptedTermsAndConditions: () => {},
  getTermsAndConditions: () => {},
  fullNameMandatory: false,
  setFullNameMandatory: () => {},
  tenantsAssociatedToUser: [],
  setTenantsAssociatedToUser: () => {},
  getTenantsAssociatedWithUser: async () => [],
  selectedTenantId: 0,
  setSelectedTenantId: () => {},
  isAppMultitenant: false,
  isProfileCompleted: false,
  setIsProfileCompleted: () => {},
  currentLanguage,
};

export const ContextApp = createContext(context);
