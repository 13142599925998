import { message as antdMessage, Modal } from 'antd';
import { ModalFuncProps } from 'antd/lib/modal';
import { useCallback, useContext } from 'react';
import { ContextApp } from '../contexts/ContextApp';
import { IError } from '../interfaces/environment';
import { Tools } from '../shared';
import { BackendError } from '../shared/BackendError';
import { T } from '../App';

export const useCustomMessage = (customT?: T) => {
  const { t: contextT } = useContext(ContextApp);
  const t = customT ?? contextT;

  const MESSAGE_CREATE_SUCCESS = t('abm.message.createSuccess');
  const MESSAGE_CREATE_ERROR = t('abm.message.createError');
  const MESSAGE_UPDATE_SUCCESS = t('abm.message.updateSuccess');
  const MESSAGE_UPDATE_ERROR = t('abm.message.updateError');
  const MESSAGE_DELETE_SUCCESS = t('abm.message.deleteSuccess');
  const MESSAGE_DELETE_ERROR = t('abm.message.deleteError');

  const DEFAULT_TIMEOUT_LOADING = 150000;
  const DEFAULT_TIMEOUT_MESSAGE = 1200;
  const DEFAULT_ERROR_MESSAGE = t('abm.message.defaultError');

  // const { Logger } = LogService();

  /**
   * Iniciar un loading
   * @param op
   */
  const messageLoading = (op: {
    context: string;
    message?: string;
    time?: number;
  }) => {
    antdMessage.destroy();
    antdMessage.loading(
      op.message || Tools.capitalize(t('action.loading')),
      op.time || DEFAULT_TIMEOUT_LOADING,
    );
    // if (op?.message && typeof op.message === 'string') {
    //   Logger.info({
    //     context: op.context,
    //     message: op.message,
    //   });
    // }
  };

  const messageDestroy = (time?: number, callback?: Function) => {
    setTimeout(() => {
      antdMessage.destroy();
      if (callback) {
        callback();
      }
    }, time || DEFAULT_TIMEOUT_MESSAGE);
  };

  const messageModalSuccess = (op: {
    context: string;
    message: string;
    props?: ModalFuncProps;
  }) => {
    antdMessage.destroy();
    Modal.success(
      op?.props || {
        content: op.message,
      },
    );
    // if (typeof op.message === 'string') {
    //   Logger.info(op);
    // }
  };

  const messageModalError = (op: {
    context: string;
    message: string;
    err?: Error;
    props?: ModalFuncProps;
  }) => {
    antdMessage.destroy();
    Modal.error(
      op?.props || {
        content: op.message,
      },
    );
    // if (op.err) {
    //   Logger.error({
    //     context: op.context,
    //     message: op.err.message,
    //   });
    // } else if (typeof op.message === 'string') {
    //   Logger.warn({
    //     context: op.context,
    //     message: op.message,
    //   });
    // }
  };

  const messageModalInfo = (op: {
    context: string;
    message: string;
    props?: ModalFuncProps;
  }) => {
    antdMessage.destroy();
    Modal.info(
      op?.props || {
        content: op.message,
      },
    );
    // if (typeof op.message === 'string') {
    //   Logger.info(op);
    // }
  };

  /**
   * Resultado de un proceso de exito
   * @param op
   */
  const messageSuccess = (op: {
    context: string;
    message: string;
    time?: number;
  }) => {
    antdMessage.destroy();
    antdMessage.success(op.message);
    messageDestroy(op.time);
    // Logger.info(op);
  };

  /**
   * Resultado de un proceso que dio error
   * @param err
   */
  const messageError = (op: {
    context: string;
    message: string;
    time?: number;
  }) => {
    antdMessage.destroy();
    antdMessage.error(op.message);
    messageDestroy(op.time);
    // Logger.warn(op);
  };

  /**
   * Para acciones de ABM
   */
  const messageCreating = (op: { context: string; message: string }) => {
    messageLoading({
      context: op.context,
      message: t('abm.message.creating', { replace: { entity: op.message } }),
    });
  };
  const messageUpdating = (op: { context: string; message: string }) => {
    messageLoading({
      context: op.context,
      message: t('abm.message.updating', { replace: { entity: op.message } }),
    });
  };
  const messageDeleting = (op: { context: string; message: string }) => {
    messageLoading({
      context: op.context,
      message: t('abm.message.deleting', { replace: { entity: op.message } }),
    });
  };

  /**
   * Para resultados de ABM
   */
  const messageCreateSuccess = (op: { context: string }) => {
    messageSuccess({ context: op.context, message: MESSAGE_CREATE_SUCCESS });
  };
  const messageCreateError = (op: { context: string }, error?: IError) => {
    //TODO: Si Carlos aprueba este complemento de error replicar a la eliminación, y actualización
    messageError({
      context: op.context,
      message:
        MESSAGE_CREATE_ERROR +
        (error?.status_code
          ? ` ${Tools.capitalize(t('entity.reason'))} ${getErrorMessage(error)}`
          : ''),
    });
  };
  const messageUpdateSuccess = (op: { context: string }) => {
    messageSuccess({ context: op.context, message: MESSAGE_UPDATE_SUCCESS });
  };
  const messageUpdateError = (op: { context: string }) => {
    messageError({ context: op.context, message: MESSAGE_UPDATE_ERROR });
  };
  const messageDeleteSuccess = (op: { context: string }) => {
    messageSuccess({ context: op.context, message: MESSAGE_DELETE_SUCCESS });
  };
  const messageDeleteError = (op: { context: string }) => {
    messageError({ context: op.context, message: MESSAGE_DELETE_ERROR });
  };

  const getErrorMessage = useCallback(
    (error: any): string => {
      if (!error) {
        return '';
      }
      if (
        error.message_translation_key &&
        error.message_translation_key !== 'error.backend.generalErrorMessage'
      ) {
        let translatedText = '';
        if (
          error.message_translation_key.includes('{{') &&
          !error.message_translation_key.includes('undefined') &&
          !error.message_translation_key.includes('{{}}')
        ) {
          translatedText = error.message_translation_key.replaceAll(
            /{{(.+?)}}/g,
            (_: string, key: string) => {
              return t(key as never);
            },
          );
        } else {
          translatedText = t(error.message_translation_key as never, {
            defaultValue: error.message,
          });
        }

        return translatedText || error.message || '';
      }
      return error.message || '';
    },
    [t],
  );

  const showMessageError = useCallback(
    (op: { context: string; error: BackendError }) => {
      const { error, context } = op;
      const { status_code } = error;
      if (status_code && status_code !== 1 && status_code !== '1') {
        messageModalError({
          context: context,
          message: getErrorMessage(error),
        });
      } else {
        messageError({
          context: context,
          message: Tools.capitalize(t('entity.generalError')),
        });
      }
    },
    [getErrorMessage],
  );

  const instantMessageDestroy = () => {
    antdMessage.destroy();
  };

  return {
    messageDestroy,
    messageLoading,
    messageSuccess,
    messageError,
    messageModalSuccess,
    messageModalError,
    messageModalInfo,
    messageCreating,
    messageUpdating,
    messageDeleting,
    messageCreateSuccess,
    messageCreateError,
    messageUpdateSuccess,
    messageUpdateError,
    messageDeleteSuccess,
    messageDeleteError,
    getErrorMessage,
    showMessageError,
    DEFAULT_ERROR_MESSAGE,
    t,
    instantMessageDestroy,
    // Logger,
  };
};
