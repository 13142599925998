export const UPDATE_ORDER_LINE = `
mutation updateOrderLine(
    $tenant_id: Int!
    $input: UpdateOrderLineInput!
){
  updateOrderLine(
    tenant_id:$tenant_id, 
    input:$input
  ){
    __typename
  	...on OrderLine{
      id      
    }
    ...on ResultError{
      status_code
      message
      message_translation_key
    }    
  }
}
`;

export const DELETE_ORDER_LINE = `
mutation deleteOrderLine(
    $id:Int!
    $tenant_id: Int!
){
  deleteOrderLine(
    id: $id
    tenant_id: $tenant_id
  ){
    __typename
    ...on OrderLine{
      id
    }
    ...on ResultError{
      status_code
      message
      message_translation_key
    }
  }
}
`;
