export const CREATE_SHOP = `
mutation createShop(
	$input:CreateShopInput!,
  $tenant_id:Int!
){
  createShop(
    input:$input,
    tenant_id:$tenant_id
  ){
    __typename
    ...on Shop{
      id      
    }
    ...on ResultError{
      status_code
      message
      message_translation_key
    }
  }
}
`;
export const UPDATE_SHOP = `
mutation updateShop(
	$input:UpdateShopInput!,
  $tenant_id:Int!
){
  updateShop(
    input:$input,
    tenant_id:$tenant_id
  ){
    __typename
    ...on Shop{
      id      
    }
    ...on ResultError{
      status_code
      message
      message_translation_key
    }
  }
}
`;
export const DELETE_SHOP = `
mutation deleteShop(
	$id:Int!,
  $tenant_id:Int!
){
  deleteShop(
    id:$id,
    tenant_id:$tenant_id
  ){
    __typename
    ...on Shop{
      id      
    }
    ...on ResultError{
      status_code
      message
      message_translation_key
    }
  }
}
`;
