/* eslint-disable no-unused-vars */
// TODO: Refactorizar para poder utilizar estos enums como tipado de variables
enum Functions {
  AppSettingRead = 'APP_SETTING_R',
  AppSettingUpdate = 'APP_SETTING_U',

  ReadTenantSetting = 'TENANT_SETTING_R',
  UpdateTenantSetting = 'TENANT_SETTING_U',

  CompanyCreate = 'COMPANY_C',
  CompanyDelete = 'COMPANY_D',
  CompanyGet = 'COMPANY_G',
  CompanyList = 'COMPANY_L',
  CompanyUpadate = 'COMPANY_U',

  OperationCreate = 'OPERATION_C',
  OperationDelete = 'OPERATION_D',
  OperationGet = 'OPERATION_G',
  OperationList = 'OPERATION_L',
  OperationUpdate = 'OPERATION_U',

  NoteRead = 'NOTE_R',
  NoteCreate = 'NOTE_C',
  NoteUpdate = 'NOTE_U',
  NoteDelete = 'NOTE_D',

  PlanRead = 'PLAN_R',
  PlanCreate = 'PLAN_C',
  PlanUpdate = 'PLAN_U',
  PlanDelete = 'PLAN_D',

  UserPlanRead = 'USER_PLAN_R',
  UserPlanCreate = 'USER_PLAN_C',
  UserPlanUpdate = 'USER_PLAN_U',
  UserPlanDelete = 'USER_PLAN_D',

  PermissionGet = 'PERMISSION_G',
  PermissionList = 'PERMISSION_L',
  PermissionUpdate = 'PERMISSION_U',

  RoleCreate = 'ROLE_C',
  RoleDelete = 'ROLE_D',
  RoleGet = 'ROLE_G',
  RoleList = 'ROLE_L',
  RoleUpdate = 'ROLE_U',
  RolePermissionCreate = 'ROLE_PERMISSION_C',
  RolePermissionGet = 'ROLE_PERMISSION_G',
  RolePermissionDelete = 'ROLE_PERMISSION_D',
  RolePermissionList = 'ROLE_PERMISSION_L',
  RolePermissionUpdate = 'ROLE_PERMISSION_U',

  UserCreate = 'USER_C',
  UserCompanyGet = 'USER_COMPANY_G',
  UserCompanyList = 'USER_COMPANY_L',
  UserCompanyUpdate = 'USER_COMPANY_U',
  UserDelete = 'USER_D',
  UserGet = 'USER_G',
  UserList = 'USER_L',
  UserRoleGet = 'USER_ROLE_G',
  UserRoleList = 'USER_ROLE_L',
  UserRoleUpdate = 'USER_ROLE_U',
  UserUpdate = 'USER_U',

  UserProfileUpdate = 'USER_PROFILE_U',
  ProfilePictureCreate = 'PROFILE_PICTURE_C',
  ProfilePictureRead = 'PROFILE_PICTURE_R',
  ProfilePictureUpdate = 'PROFILE_PICTURE_U',
  ProfilePictureDelete = 'PROFILE_PICTURE_D',

  AdministrationMenuRead = 'ADMINISTRATION_MENU_R',

  ProductAdministrationMenuRead = 'PRODUCT_ADMIN_MENU_R',

  AccountAdministrationMenuRead = 'ACCOUNT_ADMIN_MENU_R',

  TenantRead = 'TENANT_R',
  TenantCreate = 'TENANT_C',
  TenantUpdate = 'TENANT_U',
  TenantDelete = 'TENANT_D',

  CreateTenantPicture = 'TENANT_PICTURE_C',
  ReadTenantPicture = 'TENANT_PICTURE_R',
  UpdateTenantPicture = 'TENANT_PICTURE_U',
  DeleteTenantPicture = 'TENANT_PICTURE_D',

  IgnoreMaintenanceMode = 'IGNORE_MAINTENANCE_MODE',

  IsAdmin = 'IS_ADMIN',

  TermsAndConditionsUpdate = 'TERMS_AND_CONDITIONS_U',

  CategoryRead = 'CATEGORY_R',
  CategoryCreate = 'CATEGORY_C',
  CategoryUpdate = 'CATEGORY_U',
  CategoryDelete = 'CATEGORY_D',
  ProductRead = 'PRODUCT_R',
  ProductCreate = 'PRODUCT_C',
  ProductUpdate = 'PRODUCT_U',
  ProductDelete = 'PRODUCT_D',

  StockMovementRead = 'STOCK_MOVEMENT_R',
  StockMovementCreate = 'STOCK_MOVEMENT_C',

  ProductLogRead = 'PRODUCT_LOG_R',
  UnlinkUser = 'USER_UNLINK',

  SettingAdministrationMenuRead = 'CONFIGURATION_ADMIN_MENU_R',

  ShopRead = 'SHOP_R',
  ShopCreate = 'SHOP_C',
  ShopUpdate = 'SHOP_U',
  ShopDelete = 'SHOP_D',

  OrderRead = 'ORDER_R',
  OrderCreate = 'ORDER_C',
  OrderUpdate = 'ORDER_U',
  OrderDelete = 'ORDER_D',

  SupplierRead = 'SUPPLIER_R',
  SupplierCreate = 'SUPPLIER_C',
  SupplierUpdate = 'SUPPLIER_U',
  SupplierDelete = 'SUPPLIER_D',
}

enum Features {
  Notes = 'NOTES',
}

/**
 * Numeric Types
 * Name	Storage Size	Range
 * smallint	2 bytes	-32768 to +32767
 * integer	4 bytes	-2147483648 to +2147483647
 * bigint	8 bytes	-9223372036854775808 to 9223372036854775807
 */
enum MaxLengthInputs {
  SystemUser_UserName = 40,
  SystemUser_Email = 100,
  StringInputs = 200,
  NumberInputs = 20,
  NumberInputsMaxInt = 2147483647,
  CodeSap = 4,
  Region_DeliveryTerm = 2,
  CategoryName = 50,
  CategoryDescription = 200,
  ProductName = 50,
  ProductDescription = 500,
  ProductStock = 10,
  TenantName = 200,
  TenantCountry = 100,
  TenantCity = 100,
  ShopName = 250,
  ShopUrl = 200,
  FirstName = 100,
  LastName = 100,
  SupplierName = 100,
  SupplierFisicalIdType = 50,
  SupplierFisicalId = 100,
  SupplierProductAddress = 200,
}

enum InputNumberMaxValues {
  DimensionsInputs = 9999,
}

export enum LocalStorageKeys {
  RoleSelected = 'role_selected',
  TranslationsVersions = 'translation_versions',
  Translations = 'translations',
  TwoFactorValidated = 'two_factor_validated',
  Token = 'token',
  UserId = 'userId',
  TrackedQueries = 'trackedQueries',
  optimisticValuesToReplace = 'optimisticValuesToReplace',
  TwoFAOmitted = 'TwoFAOmitted',
  LastTenantId = 'LastTenantId',
  googleImage = 'googleImage',
  OrdersFilter = 'orderFilter',
  OrdersOrder = 'orderOrder',
}

export enum screenWidth {
  w992 = 992,
  w768 = 768,
  w900 = 900,
}

export enum SystemLimits {
  //Se define limite de tamaño de archivos en bytes
  /*
    Recordar que este enum debe corresponder con el de backend.
  */

  MaxSizeOfFiles = 2000000, //20 MB,
}

export enum ErrorsStatusCode {
  userIsAlreadyValidatedCode = 64,
  recordNotFoundCode = 2,
}

export enum DefaultValues {
  Title = 'Proyecto Base',
  TagColor = '#008800',
}

export enum SettingNames {
  RecoveryPasswordFrom = 'RECOVERY_PASSWORD_FROM',
  RecoveryPasswordSubject = 'RECOVERY_PASSWORD_SUBJECT',
  NodeMailerHost = 'NODEMAILER_HOST',
  NodeMailerPort = 'NODEMAILER_PORT',
  NodeMailerSecure = 'NODEMAILER_SECURE',
  NodeMailerUser = 'NODEMAILER_USER',
  NodeMailerPassword = 'NODEMAILER_PASSWORD',
  NodeMailerAccountType = 'NODEMAILER_ACCOUNT_TYPE',
  UrlSite = 'URL_SITE',
  PasswordRegex = 'PASSWORD_REGEX',
  ProfileRequired = 'PROFILE_REQUIRED',
  SecurityTokenExpiresin = 'SECURITY_TOKEN_EXPIRESIN',
  SecurityRefreshTokenExpiresin = 'SECURITY_REFRESH_TOKEN_EXPIRESIN',
  FirstLogin = 'FIRST_LOGIN',
  LimitMaxSizeFile = 'LIMIT_MAX_SIZE_FILE',
  GAClientID = 'GA_ID',
  ValidateUserByEmail = 'VALIDATE_USER_BY_EMAIL',
  UserPublicRegister = 'USERS_PUBLIC_REGISTER',
  PageTitle = 'PAGE_TITLE',
  MaintenanceMode = 'MAINTENANCE_MODE',
  MaintenanceModeMessage = 'MAINTENANCE_MODE_MESSAGE',
  AcceptTermsAndconditionsIsRequired = 'ACCEPT_TERMS_AND_CONDITIONS_IS_REQUIRED',
  FullNameMandatory = 'FULL_NAME_MANDATORY',
  MultiTenant = 'MULTI_TENANT',
  UsernameRegex = 'USERNAME_REGEX',
  AccountCommissionPercentage = 'ACCOUNT_COMMISSION_PERCENTAGE',
  AccountTransport = 'ACCOUNT_TRANSPORT',
  TrackingUrl = 'TRACKING_URL',
}

export enum PageKey {
  RedirectIfNotExist = 'redirectIfNotExist',
  DefaultRedirect = 'defaultRedirect',
  App = 'app',
  Home = 'home',
  Company = 'company',
  Operation = 'operation',
  Config2fa = 'config2fa',
  LoginMethods = 'login Methods',
  Administration = 'administration',
  ProductAdministration = 'productAdministration',
  AccountAdministration = 'accountAdministration',
  TenantSetting = 'tenant setting',
  Role = 'role',
  Permission = 'permission',
  Tenant = 'tenant',
  User = 'user',
  ManageTermsAndConditions = 'manageTermsAndConditions',
  TermsAndConditionsForAdmin = 'termsAndConditionsForAdmin',
  Setting = 'setting',
  About = 'about',
  Profile = 'profile',
  CheckCompany = 'checkCompany',
  Contact = 'contact',
  Status = 'status',
  PublicLayout = 'publicLayout',
  ResetPassword = 'resetPassword',
  SetPassword = 'setPassword',
  CompleteRegister = 'completeRegister',
  TermsAndConditions = 'termsAndConditions',
  Login = 'login',
  Register = 'register',
  Generate = 'generate',
  Reminder = 'reminder',
  ValidateTotp = 'validateTotp',
  Maintenance = 'maintenance',
  Invitations = 'invitations',
  Invitation = 'invitation',
  SelectTenant = 'selectTenant',
  Categories = 'categories',
  Products = 'products',
  ProductHistory = 'productHistory',
  MyAccount = 'myAccount',
  SettingAdminstration = 'settingAdministration',
  Connections = 'connections',
  Orders = 'orders',
  Suppliers = 'suppliers',
}

export enum TenantSettingNames {
  Allow_delete_company = 'ALLOW_DELETE_COMPANY',
}

export enum SettingBooleanString {
  True = 'true',
  False = 'false',
}

/* Recordar: si se quiere agregar un nuevo object store hay que realizar la declaracion correspondiente en el archivo IndexedDb.ts */
export enum ObjectStore {
  Company = 'companies',
  PostponedActions = 'postponedActions',
  Operation = 'operations',
  Tag = 'tags',
}

export enum ExportFormat {
  xlsx = 'xlsx',
  csv = 'csv',
  txt = 'txt',
}

export enum Themes {
  DEFAULT = 'light',
  LIGHT = 'light',
  DARK = 'dark',
}

export enum LoginProviders {
  GOOGLE = 'google.com',
  PHONE = 'phone',
  MICROSOFT = 'microsoft.com',
}

enum TermsAndConditionsStatus {
  Active = 1,
  Inactive = 2,
}

enum FormatsManageTermsAndConditions {
  Html = 'HTML',
}

enum ProductStatusId {
  Active = 1,
  Inactive = 2,
}
enum MimeTypes {
  imagesPng = 'image/png',
  imagesJpg = 'image/jpg',
  imagesJpeg = 'image/jpeg',
  applicationPdf = 'application/pdf',
}

enum Roles {
  Admin = 1,
  User = 2,
  AdminTenant = 3,
}

enum StockReasonType {
  Initial = 1,
  Addition = 2,
  Removal = 3,
}

enum SystemTenantsNames {
  SystemTenant = 'systemTenant',
}

enum SystemTenantsIds {
  SystemTenant = 1,
}

enum RoleTypes {
  Platform = 1,
  Account = 2,
}

enum ShopConnectionStatus {
  Pending = 1,
  Connected = 2,
  Disconnected = 3,
}

enum ShopPaymentType {
  CashOnDelivery = 1,
  PrePaid = 2,
}

enum TabsKeys {
  All = 'all',
}

export enum OrderActionNames {
  Update = 'update',
  SolicitRevision = 'solicitRevision',
  Delete = 'delete',
  Cancel = 'cancel',
  Confirm = 'confirm',
  Reject = 'reject',
  RegisterPayment = 'registerPayment',
  GenerateTicket = 'generateTicket',
  RegisterDispatch = 'registerDispatch',
  LabelDelivered = 'labelDelivered',
  LabelReturned = 'labelReturned',
  PrintTicket = 'printTicket',
  CancelTicket = 'cancelTicket',
}

enum TicketStatus {
  OrderDoesNotHaveTicket = 0,
  OrderHaveTicket = 1,
  OrderHaveTrackingCodeNotTicket = 3,
}

enum KeysToReplaceInStrings {
  TrackingCodeInUrl = 'space_to_insert_code',
}
export class EnumsValues {
  static readonly Functions = Functions;

  readonly Functions = EnumsValues.Functions;

  static readonly Features = Features;

  readonly Features = EnumsValues.Features;

  static readonly MaxLengthInputs = MaxLengthInputs;

  readonly MaxLengthInputs = EnumsValues.MaxLengthInputs;

  static readonly LocalStorageKeys = LocalStorageKeys;

  readonly LocalStorageKeys = EnumsValues.LocalStorageKeys;

  static readonly SettingNames = SettingNames;

  readonly SettingNames = EnumsValues.SettingNames;

  static readonly SystemLimits = SystemLimits;

  readonly SystemLimits = EnumsValues.SystemLimits;

  static readonly ErrorsStatusCode = ErrorsStatusCode;

  readonly ErrorsStatusCode = EnumsValues.ErrorsStatusCode;

  static readonly DefaultValues = DefaultValues;

  readonly DefaultValues = EnumsValues.DefaultValues;

  static readonly Themes = Themes;

  readonly Themes = EnumsValues.Themes;

  static readonly LoginProviders = LoginProviders;

  readonly LoginProviders = EnumsValues.LoginProviders;

  static readonly TermsAndConditionsStatus = TermsAndConditionsStatus;

  readonly TermsAndConditionsStatus = EnumsValues.TermsAndConditionsStatus;

  static readonly FormatsManageTermsAndConditions =
    FormatsManageTermsAndConditions;

  readonly FormatsManageTermsAndConditions =
    EnumsValues.FormatsManageTermsAndConditions;

  static readonly ProductStatusId = ProductStatusId;

  readonly ProductStatusId = EnumsValues.ProductStatusId;

  static readonly MimeTypes = MimeTypes;

  readonly MimeTypes = EnumsValues.MimeTypes;

  static readonly Roles = Roles;

  readonly Roles = EnumsValues.Roles;

  static readonly InputNumberMaxValues = InputNumberMaxValues;

  readonly InputNumberMaxValues = EnumsValues.InputNumberMaxValues;

  static readonly StockReasonType = StockReasonType;

  readonly StockReasonType = EnumsValues.StockReasonType;

  static readonly SystemTenantsNames = SystemTenantsNames;

  readonly SystemTenantsNames = EnumsValues.SystemTenantsNames;

  static readonly RoleTypes = RoleTypes;

  readonly RoleTypes = EnumsValues.RoleTypes;

  static readonly SystemTenantsIds = SystemTenantsIds;

  readonly SystemTenantsIds = EnumsValues.SystemTenantsIds;

  static readonly ShopConnectionStatus = ShopConnectionStatus;

  readonly ShopConnectionStatus = EnumsValues.ShopConnectionStatus;

  static readonly ShopPaymentType = ShopPaymentType;

  readonly ShopPaymentType = EnumsValues.ShopPaymentType;

  static readonly TabsKeys = TabsKeys;

  readonly TabsKeys = EnumsValues.TabsKeys;

  static readonly OrderActionNames = OrderActionNames;

  readonly OrderActionNames = EnumsValues.OrderActionNames;

  static readonly KeysToReplaceInStrings = KeysToReplaceInStrings;

  readonly KeysToReplaceInStrings = EnumsValues.KeysToReplaceInStrings;

  static readonly TicketStatus = TicketStatus;

  readonly TicketStatus = EnumsValues.TicketStatus;

  constructor(init?: Partial<EnumsValues>) {
    Object.assign(this, init);
  }
}
